.image-selector {
    border: 1px solid rgba(0, 0, 0, 0.2);
    max-width: 200px;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;

    .preview {
        height: 100px;
        background-color: #fafafa;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .file-input {
        position: absolute;
        top: -200000px;
    }

    .select {
        background-color: #fbc02d;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        p {
            margin: 0;
            color: white;
            font-size: 12px;
            font-weight: 800;
            text-align: center;
            padding: 5px 0px;
        }
    }
}
